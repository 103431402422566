































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.list-attrs {
  .attr-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    strong {
      color: #717171;
    }
    .attr-value {
      flex: 1 1 auto;
      padding: 8px;
      margin-left: 5px;
      margin-right: 5px;
      height: 38px;
      border-radius: 5px;
      position: relative;
      &:focus{
        border: 1px solid #e2e7f1;
        border-radius: 5px;
        outline: none;
        &:after {
          display: none;
        }
      }
      &:after {
        content: attr(data-holder);
        color: #a4a4a4;
        position: absolute;
        left: 10px;
      }
    }
    .spinner-border {
      display: none;
    }
    &.saving {
      .spinner-border {
        display: inline-block;
      }
      .attr-value {
        background: #dfdfdf;
        &:after {
          display: none;
        }
      }
    }
  }
}
.livechat-page {
  background: #fff;
  min-height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  display: flex;
  .livechat-container {
    display: flex;
    flex: 1;
  }
  .contactlist-container {
    border-right: 2px solid#ecedf0;
    display: flex;
    flex-direction: column;
    flex: 0 0 320px;
  }

  .list-users-wrapper {
    flex: 1 1 auto;
    overflow-y: auto;
    .no-contact-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      i {
        font-size: 40px;
        color: #bad2ff;
      }
      p {
        font-size: 20px;
        font-weight: bold;
        color: #bad2ff;
      }
    }
    .list-users {
      padding: 0;
      margin: 0;
      list-style: none;
      .media-body {
        position: relative;
        .name {
          max-width: 57%;
          height: 1rem;
          overflow: hidden;
        }
        .time {
          position: absolute;
          top: 0px;
          right: 0px;
          font-size: 12px;
          color: #7e858b;
        }
      }
      li {
        position: relative;
        cursor: pointer;
        padding: 11.5px 15px 11.5px 20px;
        .online {
          background-color: #4284f4;
          width: 6px;
          height: 6px;
          display: inline-block;
          border-radius: 50%;
          position: absolute;
          left: 5px;
          top: 28px;
        }
        &:hover {
          background: rgba(68, 131, 243, 0.2);
        }
        &.selected {
          background: rgba(68, 131, 243, 0.2);
        }
      }
    }
  }

  .btn-newchat {
    border: none;
    background: transparent;
    color: #4284f4;
    padding: 0px;
    font-size: 24px;
    margin-left: 10px;
    outline: none;
  }
  .search-box {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid#ecedf0;
  }
  .chat-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .chat-main {
    width: 100%;
    display: flex;
    flex: 1;
    overflow-y: hidden;
  }
  .contact-detail {
    border-left: 1px solid #ecedf0;
    padding: 20px;
    flex: 0 0 320px;
    overflow-y: auto;
    .user-detail {
      display: flex;
      margin-bottom: 40px;
      .user-info {
        margin-left: 10px;
      }
      .name {
        font-weight: 600;
        font-size: 16px;
      }
      .phone {
        font-size: 13px;
      }
    }
  }
  .chat-conversation {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .chat-messages {
      flex: 1 1 auto;
      overflow-y: auto;
      padding: 15px;
      .no-message-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        i {
          font-size: 40px;
          color: #bad2ff;
        }
        p {
          font-size: 20px;
          font-weight: bold;
          color: #bad2ff;
        }
      }
      .chat-media {
        border-radius: 5px;
        display: block;
        max-width: 240px;
        margin: 8px 0px;
        cursor: pointer;
      }
    }
    form {
      padding: 10px;
    }
  }
  .chat-header {
    text-align: center;
    border-bottom: 2px solid#ecedf0;
    position: relative;
    padding: 9px;
    min-height: 61px;
    position: relative;
    display: flex;
    h4 {
      font-size: 18px;
      flex: 1 1 auto;
    }
    .btn-outline-dark {
      font-size: 20px;
    }
    .btn-detail {
      border: none;
      background: transparent;
      color: #4284f4;
      padding: 0px;
      font-size: 22px;
      right: 15px;
      top: 15px;
      outline: none;
    }
  }
}

.list-keywords {
  .keyword-item {
    margin-right: 5px;
    span {
      padding: 2px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.4);
      margin-left: 4px;
    }
  }
}

.list-searched-contacts {
  position: absolute;
  background: #FFF;
  width: 360px;
  box-shadow: 1px 1px 5px #ccc;
  top: 61px;
  left: 0px;
  z-index: 1;
  .search-item {
    padding: 10px;
    border-bottom: 1px solid #ecedf0;
    user-select: none;
    cursor: pointer;
    &:hover {
      background: #ccc;
    }
    .search-detail {
      text-align: left;
      margin-left: 10px;
      p {
        margin-bottom: 0px;
        font-size: 12px;
      }
    }
  }
}
